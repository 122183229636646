<template>
  <div class="apply_wrapper">
    <div class="apply_head">
      <div class="hd_bg">
        <img class="hd_bg_img" src="../../../assets/images/wangshangdai/bg_applypage.jpg" alt="">
      </div>
      <div class="wrap-card">
        <div class="card-head">
          <div class="hd">
            <span class="icon icon-left"></span><span class="card-hd-text">申请云卡e付通</span> <span
            class="icon icon-right"></span></div>
        </div>
        <div class="card-content">
          <div class="codeBox"><img class="codeImg" src="../../../assets/images/wangshangdai/qrcode.png" alt=""></div>
          <div class="text_box">申请流程非常简单：</div>
          <div class="text">1、物流公司的法人使用支付宝下面的扫码，申请授信，</div>
          <div class="text">2、授信完成就可以在支付宝中搜索 <span style="color:#FD8F2A">“云卡车队管理小程序”</span></div>
        </div>
      </div>
    </div>
    <div class="footer">
      <img class="footer-img" src="../../../assets/images/wangshangdai/footer.png" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    name: "apply"
  }
</script>

<style scoped lang="less">
  .apply_wrapper {
    .apply_head {
      .hd_bg {
        position: relative;
        .hd_bg_img {
          width: 31.25rem;
          height: auto;
        }
      }
      .wrap-card {
        left: 0;
        top: 9.33rem;
        width: 28.75rem;
        height: auto;
        position: absolute;
        background-image: linear-gradient(180deg, #EBF6FF 0%, #FFFFFF 11%);
        font-size: 1.17rem;
        margin: 0 1.25rem 1.25rem 1.25rem;
        padding-bottom: 4rem;
        border: 0.2rem solid #F6F6F6;
        border-radius: 0.67rem;
        box-shadow: 0 0.5rem 0.83rem 0 rgba(150, 150, 150, 0.10), inset 0 0.08rem 0 0 #FFFFFF;
        .card-head {
          display: flex;
          justify-content: center;
          width: 100%;
          height: auto;
          padding: 1.5rem 0;
          background-image: linear-gradient(#EBF6FF, #FFFFFF);

          .hd {
            .icon {
              display: inline-block;
              width: 5rem;
              height: 0.25rem;
              margin-bottom: 0.5rem;
            }

            .icon-right {
              background-image: url('../../../assets/images/wangshangdai/line_right.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }

            .icon-left {
              background-image: url('../../../assets/images/wangshangdai/line_left.png');
              background-repeat: no-repeat;
              background-size: 95% 100%;
            }

            .card-hd-text {
              font-size: 1.5rem;
              font-weight: bold;
            }
          }
        }
        .card-content {
          .codeBox {
            margin: 2.67rem 7.7rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 11.67rem;
            height: 11.67rem;
            border-radius: 0.67rem;
            background-color: white;
            padding:1.08rem 0.83rem;
            box-shadow: 0 0.25rem 1.42rem 0 rgba(0,0,0,0.07);
            .codeImg {
              width: 11.67rem;
              height: 11.67rem;
            }
          }
          .text_box {
            font-size: 1.33rem;
            font-weight: bold;
            margin: 0.5rem 2.71rem;
            color: #757575;
          }
          .text {
            margin: 0.5rem 2.71rem;
            font-size: 1.17rem;
            color: #757575;
          }
        }
      }
    }
    .footer {
      position: absolute;
      top:55rem;
      left:3rem;
      .footer-img {
        width: 25.17rem;
        height: auto
      }
    }
  }
</style>
